(function () {
    'use strict';

    /**
     * Adds a search box and column toggle menu to a Smart Table grid.
     *
     * @method alphaSmartTableHeader
     *
     * @example
     *      HTML:
     *      <alpha-smart-table-header
     *          id="some_id"
     *          columns="someGridColumns"
     *          refresh-callback="someRefreshMethod()"></alpha-smart-table-header>
     *
     * @param {Array} columns The set of columns to toggle, in the format: {name: '', displayName: {en_US: ''}, visible: true}
     * @param {Function} [refresh-callback] The method to call when the refresh button is clicked
     */

    angular
        .module('alpha.common.alphaSmartTable')
        .directive('alphaSmartTableHeader', alphaSmartTableHeader);

        alphaSmartTableHeader.$inject = ['AlphaSmartTableService', 'Utils', 'RecordDataUtil', '$q', 'AuthenticationService', 'I18nUtil',
        'LoaderService','UserPreferences', 'UserDetailsInterface', '$window', 'Events', 'SavedSearchService', '$timeout'
    ];

    function alphaSmartTableHeader(AlphaSmartTableService, Utils, RecordDataUtil, $q, AuthenticationService, I18nUtil,
                                   LoaderService, UserPreferences, UserDetailsInterface, $window, Events, SavedSearchService, $timeout) {
        return {
            link: link,
            restrict: 'E',
            template: template,
            transclude: true,
            require: ['^alphaSmartTable', '?^stTable'],
            scope: {
                containerId: '@id',
                refreshCallback: '&',
                downloadSelectedAttachmentsCallback: '&',
                hideRefresh: '=?',
                hideDownloadButton: '=?',
                columns: '=',
                customSearchData: '=',
                chartData: '=',
                chartVisible: '=?',
                isSearchByEnter: '=?',
                chartLegendToggle: '=?',
                chartDefaultFilterParam: '@',
                masterFilterVisible: '=?',
                applyMasterFilter: '&?',
                chartDefaultFilterDataType: '@',
                refreshFromCurrentQuery: '=?',
                refreshCurrentQuery: '&?',
                asOfMaxDateVisible: '=?',
                asOfMaxDate: '&?',
                asOfDateChangeCallback: '&?',
                showAsOfDatepicker: '<?',
                queryId: '=?',
                isGridInEditMode: '=',
                emailAllowed:'=?',
                headerLinks: '=',
                anyRowsSelected: '&',
                canEmailAttachments: '&',
                openUserEmail: '&'
            }
        };

        function link(scope, element, attrs, controllers) {
            var astCtrl = controllers[0],
                stTableCtrl = controllers[1];

            // Scope methods

            scope.downloadAlphaSmartTable = downloadAlphaSmartTable;
            scope.uploadExcelReport = uploadExcelReport;
            scope.customSearchFilter = customSearchFilter;
            scope.openChart = openChart;
            scope.toggleTable = toggleTable;
            scope.isTableVisible = isTableVisible;
            scope.isToolbarVisible = isToolbarVisible;
            scope.closeChart = closeChart;
            scope.unlockAllColumns = unlockAllColumns;
            scope.getVisibleColumns = getVisibleColumns;
            scope.masterFilterAllowed = masterFilterAllowed;
            scope.openMasterDetailSearch = openMasterDetailSearch;
            scope.applyMasterFilterSearchCallBack = applyMasterFilterSearchCallBack;
            scope.getPlaceHolderText = getPlaceHolderText;
            scope.getMaxDate = getMaxDate;
            scope.isMasterFilterDisabled = isMasterFilterDisabled;
            scope.refreshCallbackHandler = refreshCallbackHandler;
            scope.cancelMasterDialog = cancelMasterDialog;
            scope.isFilterSelected = isFilterSelected;
            scope.setCurrentMasterFilter = setCurrentMasterFilter;
            scope.textSearchEntered = textSearchEntered;
            scope.refreshSavedQueryCallback = refreshSavedQueryCallback;
            scope.refreshCurrentQueryCallback = refreshCurrentQueryCallback;
            scope.isRefreshFromCurrentQuery = isRefreshFromCurrentQuery;
            scope.chartApplied = chartApplied;
            scope.asOfDateChange = asOfDateChange;
            scope.datePickerVisible = datePickerVisible;
            scope.clearMasterFilter = clearMasterFilter;
            scope.isEmailAllowed = isEmailAllowed;
            scope.getDownloadButtonTooltip = getDownloadButtonTooltip;
            scope.disableDownload = disableDownload;
            scope.disableEmail = disableEmail;
            scope.showDownloadLink = showDownloadLink;
            scope.showDownloadMsg = showDownloadMsg;
            scope.getDownloadLinkURL = getDownloadLinkURL;

            // Scope properties

            scope.chartVisible = false;
            scope.astCtrl = astCtrl;
            scope.previousMasterFilters = {};
            scope.astCtrl.asOfDate = moment().format('DD-MMM-YYYY');
            AlphaSmartTableService.resetMasterFilter();
            // Initialization
            //Make API call to fetch user preferences only if grid is for Advanced Query
            if ('showAsOfDatepicker' in attrs) {
                _isStaffUser();
            }
            scope.$watch('chartData', function (value) {
                if (_.isObject(value) && _.has(value, 'columnsSelected')) {
                    scope.chartType = value.chartType;
                    scope.measure = value.aggregateType;
                    scope.chartColumns = getChartColumns(value);
                    scope.chartVisible = true;
                }
            });

            Events.subscribe('', scope, 'alphaSmartTableHeaderDirective', ['refreshResults'], function(data) {
                if((astCtrl.showDownLoadExcelReport && data.queryId === astCtrl.showDownLoadExcelReport.id ) ||  data.queryId === scope.queryId){
                    if( astCtrl.setServerSideSearch ){
                        astCtrl.setServerSideSearch(astCtrl.currentState.searchTerm);
                    } else {
                        scope.applyMasterFilter(); //Typically for quick search.
                    }
                }
            });


            scope.$watch(function () {
                return sessionStorage.getItem('reset-as-of-date');
            }, function (newVal, oldVal) {
                sessionStorage.removeItem('reset-as-of-date');
                scope.astCtrl.asOfDate = moment().format('DD-MMM-YYYY');
            }, true);

            // Public methods

            function asOfDateChange(newDate) {
                if(newDate === null) {
                    astCtrl.asOfDate = Date.now();
                }
                scope.asOfDateChangeCallback({ newAsOfDate: newDate });
            }


            function downloadAlphaSmartTable(type) {
                var fileName,
                    visibleColumns = getVisibleColumns();
                if (visibleColumns.length && type === 'csv') {
                    _downloadTable(visibleColumns,type);
                } else if (visibleColumns.length && type === 'newMethod_csv') {
                   _downloadTable(visibleColumns,type);
                } else if (type === 'xlsx') {
                    _openXlsModal(type, astCtrl.excelQuery.name)
                        .then(function (response) {
                            fileName = response.name + '.xlsx';
                            return astCtrl.setDownloadFilter(type, response.name);
                        })
                        .then(function (response) {
                            Utils.downloadFile(fileName, response, response.type);
                        });
                } else if (type === 'new_xlsx') {
                    _openXlsModal(type, astCtrl.excelQuery.name)
                        .then(function (response) {
                            fileName = response.name + '.xlsx';
                            return astCtrl.setDownloadFilter(type, response.name);
                        })
                        .then(function (response) {
                            Utils.downloadFile(fileName, response, response.type);
                        });
                } else if (type === 'd2xl') {
                    _openXlsModal(type, astCtrl.showDownLoadExcelReport.name)
                        .then(function (response) {
                            fileName = response.name;
                            return astCtrl.setDownloadFilter(type, response.name);
                        })
                        .then(function (response) {
                            fileName = fileName + Utils.getFilenameFromResponse(response);
                            Utils.downloadFile(fileName, response.data, response.type);
                        });
                } else if (type === 'new_d2xl') {
                    _openXlsModal(type, astCtrl.showDownLoadExcelReport.name)
                        .then(function (response) {
                            fileName = response.name;
                            return astCtrl.setDownloadFilter(type, response.name);
                        })
                        .then(function (response) {
                            fileName = fileName + Utils.getFilenameFromResponse(response);
                            Utils.downloadFile(fileName, response.data, response.type);
                        });
                } else if( type === 'selected_attachments'){
                    if(disableDownload()){
                        return;
                    }
                    scope.downloadSelectedAttachmentsCallback();
                }
            }

            function uploadExcelReport(queryId) {
                _openUploadModal(queryId)
                    .then(function (filename) {
                        astCtrl.showDownLoadExcelReport.excelFilename = filename;
                    });
            }

            function closeChart() {
                scope.chartVisible = false;
                astCtrl.showTable = true;
            }

            function openChart(type) {
                scope.columnSelectionVisible = true;
                scope.chartColumns = [];
                scope.chartType = type;
                scope.chartVisible = true;
            }

            function isTableVisible() {
                return astCtrl.showTable;
            }

            function getChartColumns(chartData) {
                var chartColumns = {},
                    xAxis, yAxis;
                if (_.isObject(chartData) && _.isObject(chartData.columnsSelected)) {
                    xAxis = _.find(scope.columns, {fieldTypeId: chartData.columnsSelected.xAxis});
                    yAxis = _.find(scope.columns, {fieldTypeId: chartData.columnsSelected.yAxis});
                    chartColumns = {
                        xAxis: xAxis ? [xAxis] : [],
                        yAxis: yAxis ? [yAxis] : []
                    };
                }
                return chartColumns;
            }

            function toggleTable() {
                astCtrl.toggleTable();
            }

            function isToolbarVisible() {
                return astCtrl.isToolbarVisible();
            }

            function customSearchFilter() {
                var displayedCollection;
                if (scope.customSearchData) {
                    displayedCollection = AlphaSmartTableService.filterCollectionBySearchText(scope.customSearchData, astCtrl.searchValue, scope.columns);
                    angular.copy(displayedCollection, stTableCtrl.getFilteredCollection());
                    stTableCtrl.pipe();
                }
            }

            function _isStaffUser() {
                var deferred = $q.defer();
                UserPreferences.getCurrentPreferences()
                    .then(function (prefs) {
                        UserDetailsInterface.getUser(
                            prefs.userName, function success(userDetails) {
                                scope.isStaffUser = userDetails.debugMode;
                                deferred.resolve(userDetails);
                            },
                            function error(response) {
                                deferred.reject(response.data.errorMessage);
                            });
                    })
                    .catch(function(message) {
                        deferred.reject(message);
                    });
                return deferred.promise;
            }
            function unlockAllColumns() {
                _.forEach(scope.columns, function (column) {
                    column.locked = false;
                });
            }

            function getVisibleColumns() {
                return _.filter(scope.columns, 'visible');
            }

            function masterFilterAllowed() {
                return scope.masterFilterVisible == undefined ? false : scope.masterFilterVisible;
            }

            function datePickerVisible() {
                /*
                TODO : datePickerVisible() is called on mouse/key Event and we shouldn't be calling any business logic from here.
                TODO : Please Check and fix. Commenting the code for now.
                astCtrl.asOfDate = Date.now();
                if(!scope.asOfDateChangeCallback){
                    asOfDateChange(Date.now());
                }
                */
                return scope.asOfMaxDateVisible == undefined ? false : scope.asOfMaxDateVisible;
            }

            function openMasterDetailSearch(event) {
                if(!isMasterFilterDisabled()) {
                    _applyMasterSearchDefaultFilters(astCtrl.showDownLoadExcelReport.id ? astCtrl.showDownLoadExcelReport.id : scope.queryId, event);
                }
            }

            function getPlaceHolderText() {
                if (masterFilterAllowed()) {
                    return I18nUtil.getI18nString('PH_SEARCH_WITHIN_RESULTS', 'Search within Results');
                } else {
                    return I18nUtil.getI18nString('PH_SEARCH', 'Search');
                }
            }

            function getMaxDate() {
                return moment();

            }


            function applyMasterFilterSearchCallBack(masterFilterChanges) {
                AlphaSmartTableService.setMasterFilterApplied(masterFilterChanges && masterFilterChanges.terms && masterFilterChanges.terms.length > 0, masterFilterChanges.queryName);
                AlphaSmartTableService.setMasterSearchFilter(masterFilterChanges, scope.queryId);
                scope.applyMasterFilter();
            }

            function isFilterSelected() {
                return AlphaSmartTableService.isMasterFilterApplied(_getQueryId());
            }

            function setCurrentMasterFilter(filters, queryName) {
                AlphaSmartTableService.setMasterFilter(filters, queryName);
                scope.previousMasterFilters = angular.copy(filters);
            }
            /*
             * This method returns true if Filter should be disabled.
             */
            function isMasterFilterDisabled() {
                if(scope.queryId){
                    return false;
                }
                return  !_.get(astCtrl.showDownLoadExcelReport,'id');
            }

            /*
             * This method gets called from search throughout the application
             */

            function textSearchEntered() {
                astCtrl.setServerSideSearch(astCtrl.currentState.searchTerm);
            }

            function refreshCallbackHandler(queryName) {
                $timeout(function (){
                    if(astCtrl.currentState && !_.isEmpty(astCtrl.currentState.searchTerm) && astCtrl.showDownLoadExcelReport) {
                        _clearMasterFilter(astCtrl.showDownLoadExcelReport.id);
                    } else if(scope.queryId) {
                        _clearMasterFilter(scope.queryId);
                    }

                    if(astCtrl.currentState) {
                        astCtrl.currentState.searchTerm="";
                    }
                    scope.refreshCallback();
                    _resetAsOfDatePicker();
                    if(astCtrl.showDownLoadExcelReport!=undefined) {
                        Events.publish('userFilterController', 'HideInGridFilter', {queryName: astCtrl.showDownLoadExcelReport.id ? astCtrl.showDownLoadExcelReport.id : scope.queryId});
                    }
                }, 10); //A 10 milli second delay provides opportunity for lookup popups to close.
            }

            function cancelMasterDialog() {
                var refreshNeeded = true;
                _.forEach(AlphaSmartTableService.getMasterFilter(astCtrl.showDownLoadExcelReport.id), function (column) {
                    if(column.operator) {
                        refreshNeeded = false;
                    }
                });
                if(refreshNeeded) {
                    if(isFilterSelected(astCtrl.showDownLoadExcelReport.id)) {
                        if(isRefreshFromCurrentQuery()) {
                            scope.refreshCurrentQuery();
                        } else {
                            scope.refreshCallback();
                        }
                    }
                    AlphaSmartTableService.setMasterFilterApplied(false, astCtrl.showDownLoadExcelReport.id);
                } else {
                    AlphaSmartTableService.setMasterFilter(angular.copy(scope.previousMasterFilters), astCtrl.showDownLoadExcelReport.id);
                }
            }
            function isRefreshFromCurrentQuery() {
                return scope.refreshFromCurrentQuery ? true : false;
            }

            function isEmailAllowed(){
                return scope.emailAllowed ? true : false;
            }

            function getDownloadButtonTooltip(){
                var label;
                if(scope.headerLinks && scope.headerLinks.length > 0) {
                    if (scope.headerLinks[0].status === "1") {
                        return I18nUtil.getI18nString('LBL_DOWNLOAD_LIMITATION', 'You can only queue one download for a record at a time');
                    }
                }
                if(!scope.anyRowsSelected()){
                    label = I18nUtil.getI18nString('LBL_SELECT_ATTACHMENTS', 'Select attachments to download');
                } else {
                    label = I18nUtil.getI18nString('LBL_PREPARE_DOWNLOAD', 'Prepare attachments for download');
                }
                return label;
            }

            /**
             * Disable the download button if there is already a download in the queue or
             * no row is selected in the attachments list for download.
             * @returns {boolean|*}
             */
            function disableDownload(){
                var someRowsSelected = scope.anyRowsSelected();
                var unprocessedDownloads = scope.headerLinks && scope.headerLinks.length > 0 && scope.headerLinks [0].status === "1";
                return (unprocessedDownloads || !someRowsSelected);
            }

            function disableEmail(){
                return !AlphaSmartTableService.canShowEmailOption() || !scope.canEmailAttachments();
            }

            function showDownloadLink(){
                return  scope.headerLinks && scope.headerLinks.length > 0 && scope.headerLinks[0].status ==='2';
            }

            function showDownloadMsg(){
                return scope.headerLinks && scope.headerLinks.length > 0 && scope.headerLinks [0].status === "1";
            }

            function getDownloadLinkURL(){
                if(showDownloadLink()){
                    return scope.headerLinks[0].link;
                }
            }

            function chartApplied() {
                if(astCtrl.showDownLoadExcelReport) {
                    _clearMasterFilter(astCtrl.showDownLoadExcelReport.id);
                }
            }

            function refreshSavedQueryCallback() {
                    if(astCtrl.showDownLoadExcelReport){
                        _clearMasterFilter(astCtrl.showDownLoadExcelReport.id);
                    }
                    angular.forEach(astCtrl.columns,function (column){
                        if(column.sortDirection != null){
                            column.sortDirection = null;
                        }
                    });
                    AlphaSmartTableService.resetMasterFilter();
                    astCtrl.currentState.searchTerm="";
                    scope.refreshCallback();
                    _resetAsOfDatePicker();
                    Events.publish('userFilterController', 'HideInGridFilter', {queryName: astCtrl.showDownLoadExcelReport.id ? astCtrl.showDownLoadExcelReport.id : scope.queryId });
            }

            function refreshCurrentQueryCallback() {
                    if(astCtrl.showDownLoadExcelReport){
                        _clearMasterFilter(astCtrl.showDownLoadExcelReport.id);
                    }
                    AlphaSmartTableService.resetMasterFilter();
                    astCtrl.currentState.searchTerm="";
                    scope.refreshCurrentQuery();
                    if(astCtrl.asOfDate === null) {
                        astCtrl.asOfDate = Date.now();
                    }
                    Events.publish('userFilterController', 'HideInGridFilter', {queryName: astCtrl.showDownLoadExcelReport.id ? astCtrl.showDownLoadExcelReport.id : scope.queryId });
            }

            function clearMasterFilter() {
                _clearMasterFilter(_getQueryId());
                scope.applyMasterFilter();
            }

            // Private functions

            function _clearMasterFilter(queryName) {
                if(queryName) {
                    _.forEach(AlphaSmartTableService.getMasterFilter(queryName), function (column) {
                        column.operator = SavedSearchService.getSelectDefaultOperator(column._fieldType);
                        column.value = undefined;
                        column.maxValue = undefined;
                    });

                    AlphaSmartTableService.setMasterSearchFilter({}, queryName);
                    AlphaSmartTableService.setMasterFilterApplied(false, queryName);
                }
            }
            function _downloadTable(visibleColumns,type) {
                _getCsvDataSet(type)
                    .then(function (data) {
                        AlphaSmartTableService.getCsvSeparatorValue()
                            .then(function (separator) {
                                if(type == 'newMethod_csv')
                                {
                                    Utils.downloadFile(scope.containerId + '.csv', data, 'data:attachment/text;charset=utf-8');
                                }else {
                                    var csvData = _createCsv(data, visibleColumns, separator);
                                    Utils.downloadFile(scope.containerId + '.csv', csvData, 'data:attachment/text;charset=utf-8');
                                }
                            })
                            .catch(function (response) {
                                deferred.reject(response.data);
                            });
                    })
                    .catch(function (response) {
                        deferred.reject(response.data);
                    });


                function _createCsv(dataSet, visibleColumns, separator) {
                    {
                        var csvContent = _.concat([_getCsvHeader(separator)], _getCsvRows(separator)).join('\r\n'),
                            byteOrderMark = '\ufeff'; // marks this as a UTF-8 file so it can be read accurately
                        return byteOrderMark + csvContent;
                    }

                    function _getCsvHeader(separator) {
                        var downloadColumns = visibleColumns;
                        if (astCtrl.setDownloadCallback) {
                            downloadColumns = getUserPermissionColumns(visibleColumns);
                        }
                        return _.chain(downloadColumns)
                            .map(function (column) {
                                var displayValue = _.isObject(column.displayName) ? RecordDataUtil.getDisplayValue(column.displayName, 'Description') : column.displayName;
                                return _getFormattedValue(displayValue);
                            })
                            .join(separator)
                            .value();
                    }

                    function _getCsvRows(separator) {
                        return _.map(dataSet, function (row) {
                            return _.chain(visibleColumns)
                                .map(function (column) {
                                    var displayValue;
                                    if(column.clientInfo){
                                        displayValue = column.getter ? column.getter(row, column.name) : row[column.name];
                                    } else {
                                        displayValue = column.getter ? column.getter(row, column.csvFormateChange) : row[column.name];
                                    }
                                    return _getFormattedValue(displayValue);
                                })
                                .join(separator)
                                .value();
                        });
                    }

                    function _getFormattedValue(value) {
                        return '"' + _.replace(value, /"/g, '""') + '"';
                    }
                }

                function _getCsvDataSet(type) {
                    if (astCtrl.serverSideMode && astCtrl.setDownloadFilter) {
                        return astCtrl.setDownloadFilter(type);
                    } else if (astCtrl.serverSideMode) {
                        return $q.when(astCtrl.displayData);
                    } else if (astCtrl.setDownloadCallback) {
                        return astCtrl.setDownloadCallback();
                    } else {
                        return $q.when(stTableCtrl.getFilteredCollection());
                    }
                }
            }
            function _openXlsModal(downLoadType, queryName) {
                var options = {
                    animation: false,
                    windowClass: 'modal-default',
                    templateUrl: applicationContextRoot + '/static/custom/common/alphaSmartTable/partials/modals/alphaSmartTableDownload.html',
                    controller: 'AlphaSmartTableDownloadController',
                    controllerAs: 'dlCtrl',
                    resolve: {
                        queryName: function () {
                            return queryName;
                        },
                        downLoadType: function () {
                            return downLoadType;
                        }
                    }
                };
                return AuthenticationService.openAuthenticatedModal(options);
            }

            function _openUploadModal(queryId) {
                var options = {
                    animation: false,
                    windowClass: 'modal-default',
                    templateUrl: applicationContextRoot + '/static/custom/common/alphaSmartTable/partials/modals/alphaSmartTableUpload.html',
                    controller: 'AlphaSmartTableUploadController',
                    controllerAs: 'uploadCtrl',
                    resolve: {
                        queryId: function () {
                            return astCtrl.showUploadExcelReport;
                        }
                    }
                };
                return AuthenticationService.openAuthenticatedModal(options);
            }

            function _applyMasterSearchDefaultFilters(queryName, event) {
                var searchQuery,
                filters = {};
                LoaderService.startLoading();
                if(_.isEmpty(AlphaSmartTableService.getMasterFilter(queryName))) {
                    AlphaSmartTableService.getSavedSearch(queryName)
                        .then(function (savedSearch) {
                            searchQuery = savedSearch;
                            return AlphaSmartTableService.getRecordTypesForQuery(searchQuery);
                        })
                        .then(function (recordTypes) {
                            _.forEach(getVisibleColumns(), function (column) {
                                var name = column.name;
                                var filter = {
                                    _fieldType: _.find(recordTypes[column.recordTypeId].allFields, {id: column.fieldTypeId}),
                                    operator: undefined,
                                    name: name
                                };
                                filters[name] = filter;
                            });
                            AlphaSmartTableService.setMasterFilter(filters, queryName);
                            Events.publish('userFilterController', 'ShowInGridFilter', {queryName: queryName, filters: filters});
                        })
                        .finally(LoaderService.stopLoading);
                } else {
                    Events.publish('userFilterController', 'ShowInGridFilter', {queryName: queryName, filters: AlphaSmartTableService.getMasterFilter(queryName)});
                    LoaderService.stopLoading();
                }
                return filters;
            }

            function _getQueryId() {
                return _.get(astCtrl.showDownLoadExcelReport, 'id') ? astCtrl.showDownLoadExcelReport.id : scope.queryId;
            }

            /**
             * Reset As of date picker/Prompt date in AQ/QS result to today's date
             */
            function _resetAsOfDatePicker() {
                if (scope.showAsOfDatepicker) {
                    scope.astCtrl.asOfDate = moment().format('DD-MMM-YYYY');
                }
            }

        }

        function template(element, attrs) {
            var html = '<alpha-smart-table-chart ng-if="chartVisible" ' +
                'id="{{containerId}}__chart" ' +
                'class="alpha-smart-table-chart" ' +
                'chart-type="chartType" ' +
                'close-chart="closeChart()"' +
                'column-selection-visible="columnSelectionVisible"' +
                'columns="columns"' +
                'measure="measure"' +
                'chart-columns="chartColumns"' +
                'chart-legend-toggle="chartLegendToggle"' +
                'chart-default-filter-param="{{chartDefaultFilterParam}}"' +
                'clear-master-filter="chartApplied()"' +
                'chart-default-filter-data-type="{{chartDefaultFilterDataType}}"></alpha-smart-table-chart>' +
                '<div class="pull-left">' +
                '<alpha-toggle-slider id="{{containerId}}__toggleTable" class="alpha-table-switch"' +
                'on-text="{{\'LBL_HIDE_TABLE\' | translate}}"' +
                'off-text="{{\'LBL_SHOW_TABLE\' | translate}}"' +
                'value="isTableVisible()"' +
                'display-only="true"' +
                'ng-if="chartVisible"' +
                'ng-click="toggleTable()"></alpha-toggle-slider></div>' +
                '<div class="alpha-smart-table-header" ng-class="{\'alpha-smart-table-header-unset-scroll\': showAsOfDatepicker}"  ng-show="isTableVisible() && isToolbarVisible()">' +
                '<span class="attachment-download-link" ng-if="showDownloadLink()">' +
                '   <a class="alpha-button-reset alpha-field-history-btn" ng-href="{{getDownloadLinkURL()}}" target="_blank" translate="LBL_ATTACHMENTS_READY">Attachments ready for download</a>' +
                '</span>'+
                '<span class="attachment-download-link" ng-if="showDownloadMsg()" translate="LBL_ATTACHMENTS_PREPARATION">Download: email on completion or refresh to see if complete.</span>'+
                '<div ng-if="showAsOfDatepicker" class="alpha-table-header-dackpicker-wrapper">' +
                '<alpha-date-time-input id="asOfDate"' +
                'ng-if="datePickerVisible()"' +
                'name="asOfDate"' +
                'placeholder-text="{{\'LBL_PROMPT_PLACEHOLDER_TEXT\' | translate}}"' +
                'enable-time="false"' +
                'show-calender-icon="true"' +
                'ng-model="astCtrl.asOfDate"' +
                'tooltip-text="{{\'LBL_PROMPT_PLACEHOLDER_TEXT\' | translate}}"' +
                'max-date="getMaxDate()"'+
                'ng-change="asOfDateChange(astCtrl.asOfDate)"' +
                'disabledinput="astCtrl.isDatePickerDisabled" ' +
                'date-model-format="\'DD-MMM-YYYY\'"></alpha-date-time-input></div>' +
                '<div ng-if="masterFilterAllowed()" class="alpha-icon-button">'+
                '<button class="alpha-icon-button-filter"  uib-tooltip="{{\'LBL_FILTER\' | translate}}" tooltip-append-to-body="true">'+
                '<a href="" id="test_show_master_filter_menu"' +
                ' ng-if="masterFilterAllowed()" ' +
                ' ng-click="openMasterDetailSearch($event)"' +
                ' ng-disabled="isMasterFilterDisabled()" ' +
                ' class="alpha-icon-button-filter">' +
                '<span id="alpha_smart_table_filter" ' +
                'class="glyphicon glyphicon-filter alpha-smart-table-filter-icon"   ng-class="{ filterApply : (isFilterSelected()),filterDisabled : (isMasterFilterDisabled()) }">' +
                '</span></a></button>' +
                '<button class="alpha-icon-button-filter"' +
                '        ng-click="clearMasterFilter()"' +
                '        ng-show="isFilterSelected()"' +
                '        uib-tooltip="' + I18nUtil.getI18nString('BTN_RESET_FILTER', 'Reset filter') + '"' +
                '        tooltip-append-to-body="true">' +
                '        <span class="query_master_filter_reset">&times;</span>' +
                '</button>' +
                '</div>'+
                '<input ' + (_.isUndefined(attrs.customSearchData) ? 'st-search' : ' ng-change="customSearchFilter()" ') +
                ' ng-if="!astCtrl.serverSideMode && !astCtrl.setServerSideSearch"' +
                ' id="{{containerId}}__search_input"' +
                ' type="search"' +
                ' class="form-control input-sm alpha-smart-table-search"' +
                ' ng-model="astCtrl.searchValue"' +
                " placeholder=\"{{getPlaceHolderText()}}\">" +

                '<input ng-keydown="$event.keyCode === 13 ? astCtrl.setServerSideSearch(astCtrl.currentState.searchTerm):undefiend"' +
                ' ng-if="astCtrl.setServerSideSearch && isSearchByEnter"' +
                ' id="{{containerId}}__search_input"' +
                ' type="search"' +
                ' class="form-control input-sm alpha-smart-table-search"' +
                ' ng-model="astCtrl.currentState.searchTerm"' +
                " placeholder=\"{{getPlaceHolderText()}}\">" +

                '<input ng-change="textSearchEntered()"' +
                ' ng-if="astCtrl.setServerSideSearch && !isSearchByEnter"' +
                ' ng-model-options="{debounce: 1000}"' +
                ' id="{{containerId}}__search_input"' +
                ' type="search"' +
                ' class="form-control input-sm alpha-smart-table-search"' +
                ' ng-model="astCtrl.currentState.searchTerm"' +
                " placeholder=\"{{getPlaceHolderText()}}\">" +

                '<a id="{{containerId}}__show_filter_menu"' +
                ' ng-if="(astCtrl.filterMenuList && astCtrl.filterMenuList.length>0)" ' +
                ' class="alpha-icon-button"' +
                ' popover-class="alpha-smart-table-menu"' +
                ' uib-popover-template="astCtrl.filterMenuTemplate ? {{\'astCtrl.filterMenuTemplate\'}} :  \'' + applicationContextRoot + '/static/custom/common/app/partials/popovers/alphaSmartTableFilterMenu.html\'"' +
                ' popover-placement="bottom"' +
                ' popover-trigger="focus"' +
                ' tabindex="-1"' +
                ' uib-tooltip="{{\'LBL_FILTER\' | translate}}" tooltip-append-to-body="true"><span class="glyphicon glyphicon-filter alpha-smart-table-filter-icon"></span></a>' +
                '<a id="{{containerId}}__show_column_list"' +
                ' class="alpha-icon-button"' +
                ' popover-class="alpha-smart-table-menu"' +
                ' uib-popover-template="\'' + applicationContextRoot + '/static/custom/common/app/partials/popovers/alphaSmartTableColumnToggle.html\'"' +
                ' popover-placement="bottom"' +
                ' uib-tooltip="{{\'LBL_COLUMNS\' | translate}}" tooltip-append-to-body="true"><span class="ventiv-icon icon-ic_home"></span></a>' +
                '<a id="{{containerId}}__show_charting"' +
                ' ng-if="astCtrl.chartingIsEnabled" ' +
                ' class="alpha-icon-button alpha-smart-table-chart-button"' +
                ' popover-class="alpha-smart-table-menu"' +
                ' uib-popover-template="\'' + applicationContextRoot + '/static/custom/common/alphaSmartTable/partials/popovers/alphaSmartTableChartSelection.html\'"' +
                ' popover-placement="bottom"' +
                ' popover-trigger="focus"' +
                ' tabindex="-1"' +
                ' uib-tooltip="{{\'LBL_CHART\' | translate}}" tooltip-append-to-body="true">' +
                '<span class="ventiv-icon icon-ic_dashboard"></span><span class="caret"></span></a>';
            if (!_.isEmpty(attrs.refreshCallback)) {
                html += '<a id="{{containerId}}__refresh"' +
                    ' class="alpha-icon-button"' +
                    ' uib-tooltip="{{\'LBL_REFRESH\' | translate}}" tooltip-append-to-body="true"' +
                    ' ng-click="refreshCallbackHandler(astCtrl.showDownLoadExcelReport.id)"' +
                    ' ng-if="!isRefreshFromCurrentQuery()" ' +
                    ' ng-hide="hideRefresh"  ><span class="ventiv-icon icon-ic_reset2"></span></a>';

                html += '<a id="{{containerId}}__refresh"' +
                    ' class="alpha-icon-button"' +
                    ' uib-tooltip="{{\'LBL_REFRESH\' | translate}}" tooltip-append-to-body="true"' +
                    ' popover-class="alpha-download-popover alpha-smart-table-menu" ' +
                    ' popover-placement="left" ' +
                    ' popover-trigger="focus" ' +
                    ' uib-popover-template="\'' + applicationContextRoot + '/static/custom/common/alphaSmartTable/partials/popovers/alphaSmartTableRefreshList.html\'" ' +
                    ' tabindex="-1" ' +
                    ' ng-if="isRefreshFromCurrentQuery()" ' +
                    ' ng-hide="hideRefresh"  ><span class="ventiv-icon icon-ic_reset2"></span></a>';
            }

            html += '<a id="{{containerId}}__attachments_download"' +
                ' class="alpha-icon-button alpha-smart-table-download-button" ' +
                ' ng-disabled="disableDownload()" ng-class="{\'disabled\': (!getVisibleColumns().length || disableDownload())}"'+
                ' ng-if="emailAllowed" ' +
                ' tabindex="-1" ' +
                ' uib-tooltip="{{getDownloadButtonTooltip()}}" tooltip-class="flicker attachment-tooltip-top" tooltip-append-to-body="true"' +
                ' ng-mousedown="downloadAlphaSmartTable(\'selected_attachments\')"> ' +
                ' <span class="glyphicon-export"></span></a>';

            html +='<a id="{{containerId}}__smart_table_email"'  +
                '        class="alpha-icon-button alpha-smart-table-download-button" ' +
                '        ng-class="{\'disabled\': !getVisibleColumns().length}" ' +
                '        ng-if="isEmailAllowed()"' +
                '        ng-click="openUserEmail()"' +
                '        ng-disabled="disableEmail()"' +
                '        uib-tooltip="' + I18nUtil.getI18nString('BTN_EMAIL', 'Reset filter') + '"' +
                '        tooltip-append-to-body="true">' +
                '       <span class="glyphicon glyphicon-envelope"></span>'
                '</a>';

            html += '<a id="{{containerId}}__smart_table_download"' +
                ' class="alpha-icon-button alpha-smart-table-download-button" ' +
                ' ng-class="{\'disabled\': !getVisibleColumns().length}" ' +
                ' popover-class="alpha-download-popover alpha-smart-table-menu" ' +
                ' popover-placement="left" ' +
                ' popover-trigger="focus" ' +
                ' ng-if="!hideDownloadButton && !emailAllowed" ' +
                ' tabindex="-1" ' +
                ' uib-popover-template="\'' + applicationContextRoot + '/static/custom/common/alphaSmartTable/partials/popovers/alphaSmartTableDownloadList.html\'" ' +
                ' uib-tooltip="{{\'LBL_DOWNLOAD\' | translate}}" tooltip-append-to-body="true"><span class="glyphicon-export"></span></a>';
            html += '<ng-transclude ng-show="isTableVisible()"></ng-translude></div>';
            return html;
        }

        function getUserPermissionColumns(visibleColumns) {
            var downloadColumns = visibleColumns;
            downloadColumns.push({
                name: 'disableTopMenusAuthority',
                displayName: I18nUtil.getI18nString('LBL_DISABLE_TOP_MENUS', 'Disable Top Menus'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.disableTopMenusAuthority;
                }
            }, {
                name: 'showAdministrationSetting',
                displayName: I18nUtil.getI18nString('LBL_ADMIN_ACCESS', 'Admin Access'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.showAdministrationSetting;
                }
            }, {
                name: 'businessIntelligenceAuthority',
                displayName: I18nUtil.getI18nString('LBL_BUSINESS_INTELLIGENCE_ACCESS', 'Business Intelligence'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.businessIntelligenceAuthority;
                }
            }, {
                name: 'lookupMaintenanceAuthority',
                displayName: I18nUtil.getI18nString('LBL_LOOKUP_MAINTENANCE_ACCESS', 'Lookups'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.lookupMaintenanceAuthority;
                }
            }, {
                name: 'formLetterAuthority',
                displayName: I18nUtil.getI18nString('LBL_FORM_LETTER_MAINTENANCE_ACCESS', 'Form Letters'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.formLetterAuthority;
                }
            }, {
                name: 'rateSchemeAuthority',
                displayName: I18nUtil.getI18nString('LBL_RATE_SCHEME_UPLOAD', 'Rate Scheme Upload'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.rateSchemeAuthority;
                }
            }, {
                name: 'toolAccess',
                displayName: I18nUtil.getI18nString('LBL_TOOL_ACCESS', 'Tool Access'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.dataModelDesignAuthority;
                }
            }, {
                name: 'securityAssignmentAuthority',
                displayName: I18nUtil.getI18nString('LBL_USER_GROUP_ACCESS', 'Users and Groups'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.securityAssignmentAuthority;
                }
            }, {
                name: 'resetPasswordsOnlyAuthority',
                displayName: I18nUtil.getI18nString('LBL_RESET_PASSWORDS_ONLY', 'Reset Passwords Only'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.resetPasswordsOnlyAuthority;
                }
            }, {
                name: 'allClientUserAuthority',
                displayName: I18nUtil.getI18nString('LBL_ALL_CLIENT_USER', 'Manage All Client Users'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.allClientUserAuthority;
                }
            }, {
                name: 'rulePLSQLAccess',
                displayName: I18nUtil.getI18nString('LBL_RULE_PLSQL_ACCESS', 'Rule PL/SQL access'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.rulePLSQLAccess;
                }
            }, {
                name: 'workFlowAutomationAuthority',
                displayName: I18nUtil.getI18nString('LBL_WORKFLOW_AUTOMATION', 'Workflow Automation'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.workFlowAutomationAuthority;
                }
            }, {
                name: 'recordMenuAuthority',
                displayName: I18nUtil.getI18nString('LBL_RECORDS_ACCESS', 'Records Access'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.recordMenuAuthority;
                }
            }, {
                name: 'addRecordAuthority',
                displayName: I18nUtil.getI18nString('LBL_ADD_RECORDS_PERM', 'Add Records'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.addRecordAuthority;
                }
            }, {
                name: 'recentRecordAuthority',
                displayName: I18nUtil.getI18nString('LBL_RECENT_RECORDS_PERM', 'Recent Records'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.recentRecordAuthority;
                }
            }, {
                name: 'dataGovernanceAuthority',
                displayName: I18nUtil.getI18nString('LBL_DATA_GOVERNANCE_PERM', 'Data Governance'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.dataGovernanceAuthority;
                }
            }, {
                name: 'editRecordCurrencyAuthority',
                displayName: I18nUtil.getI18nString('LBL_EDIT_RECORD_CURRENCY_PERM', 'Change Record Currency'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.editRecordCurrencyAuthority;
                }
            }, {
                name: 'analyticsAuthority',
                displayName: I18nUtil.getI18nString('LBL_ANALYTICS_ACCESS', 'Analytics Access'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.analyticsAuthority;
                }
            }, {
                name: 'globalSearchAuthority',
                displayName: I18nUtil.getI18nString('LBL_GLOBAL_SEARCH', 'Global Search'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.globalSearchAuthority;
                }
            }, {
                name: 'mapSearchAuthority',
                displayName: I18nUtil.getI18nString('LBL_MAP_SEARCH', 'Map Search'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.mapSearchAuthority;
                }
            }, {
                name: 'advanceQueryAuthority',
                displayName: I18nUtil.getI18nString('LBL_ADVANCED_QUERY', 'Advanced Query'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.advanceQueryAuthority;
                }
            }, {
                name: 'createEditAnyPublicAdvanceQuery',
                displayName: I18nUtil.getI18nString('LBL_ANY_ADVANCE_QUERY', 'Create/Edit Any Advance Query'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.createEditAnyPublicAdvanceQuery;
                }
            }, {
                name: 'deleteAnyPublicAdvanceQuery',
                displayName: I18nUtil.getI18nString('LBL_DELETE_ANY_ADVANCE_QUERY', 'Delete Any Advance Query'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.deleteAnyPublicAdvanceQuery;
                }
            }, {
                name: 'quickSearchAuthority',
                displayName: I18nUtil.getI18nString('LBL_QUICK_FORM_LETTER_SEARCH', 'Create/Edit Quick or Form Letter Search'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.quickSearchAuthority;
                }
            }, {
                name: 'spreadSheetUploadAuthority',
                displayName: I18nUtil.getI18nString('LBL_SPREADSHEET_UPLOAD', 'Spreadsheet Upload'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.spreadSheetUploadAuthority;
                }
            }, {
                name: 'byPassRuleAuthority',
                displayName: I18nUtil.getI18nString('LBL_BYPASS_RULE', 'Bypass Rule'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.byPassRuleAuthority;
                }
            }, {
                name: 'reportAuthority',
                displayName: I18nUtil.getI18nString('LBL_REPORT_PERM', 'Reports'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.reportAuthority;
                }
            }, {
                name: 'editReportAuthority',
                displayName: I18nUtil.getI18nString('LBL_EDIT_REPORT', 'Edit Report'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.editReportAuthority;
                }
            }, {
                name: 'deleteReportAuthority',
                displayName: I18nUtil.getI18nString('LBL_DELETE_REPORT', 'Delete Report'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.deleteReportAuthority;
                }
            }, {
                name: 'createPublicReportAuthority',
                displayName: I18nUtil.getI18nString('LBL_MAKE_PUBLIC', 'Make Public'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.createPublicReportAuthority;
                }
            }, {
                name: 'dataDiscoveryAuthority',
                displayName: I18nUtil.getI18nString('LBL_DATA_DISCOVERY', 'Data Discovery'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.dataDiscoveryAuthority;
                }
            }, {
                name: 'dataScienceAuthority',
                displayName: I18nUtil.getI18nString('LBL_DATA_SCIENCE', 'Data Science'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.dataScienceAuthority;
                }
            }, {
                name: 'createEditUsersDashboards',
                displayName: I18nUtil.getI18nString('LBL_CREATE_USER_DASHBOARDS', 'Create/Edit User\'s Dashboards'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.createEditUsersDashboards;
                }
            }, {
                name: 'deleteUsersDashboards',
                displayName: I18nUtil.getI18nString('LBL_DELETE_USER_DASHBOARDS', 'Delete User\'s Dashboards'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.deleteUsersDashboards;
                }
            }, {
                name: 'createEditAnyPublicDashboards',
                displayName: I18nUtil.getI18nString('LBL_ANY_DASHBOARDS', 'Create/Edit Any Dashboard'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.createEditAnyPublicDashboards;
                }
            }, {
                name: 'deleteAnyPublicDashboards',
                displayName: I18nUtil.getI18nString('LBL_DELETE_ANY_DASHBOARDS', 'Delete Any Dashboard'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.deleteAnyPublicDashboards;
                }
            }, {
                name: 'clientDashboardFavorites',
                displayName: I18nUtil.getI18nString('LBL_CLIENT_DASHBOARD_FAVORITES', 'Client Dashboard Favorites'),
                visible: true,
                width: '10%',
                getter: function (row) {
                    return row.clientDashboardFavorites;
                }
            });
            return downloadColumns;
        }
    }
})();
